.rodape {
  background: linear-gradient(to bottom, #048552, #235742);
  padding: 10px;
  color: var(--background-light);
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  /* Removido position: fixed e bottom: 0 */
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1; /* Isso garante que o conteúdo ocupe o espaço disponível */
}

.links-rodape {
  display: flex;
  flex-direction: column; 
  align-items: center;
  text-decoration: none; 
  color: inherit;
  margin: 0; 
}

.redes-sociais {
  display: flex;
  gap: 10px; 
  justify-content: center; 
  margin-top: 5px; 
}

.redes-sociais a {
  color: var(--text-color); 
  font-size: 20px; 
  transition: color 0.3s; 
}

.redes-sociais a:hover {
  color: var(--text-highlight); 
}

@media (max-width: 768px) { 
  .rodape {
      padding: 10px; 
  }

  .redes-sociais {
      flex-direction: row; 
  }

  .conteudo-rodape-aplicativo, .conteudo-rodape-inicial {
      flex-direction: column; 
      align-items: center; 
  }
}
