body {
  background-color: #f6f6f6;
  font-family: 'Arial', sans-serif;
  margin: 0;
}

.container {
  margin-top: 20px;
}

h1 {
  color: #333;
}

.saldo-card {
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.alert {
  margin-top: 20px;
  text-align: center;
}

.card {
  border-radius: 8px;
  margin-top: 20px;
}

.btn {
  border-radius: 20px;
}

.progress {
  height: 20px;
  border-radius: 10px;
}

@media (max-width: 768px) {
  h1 {
    font-size: 24px;
  }

  .container {
    padding: 10px;
  }
}

.card-body {
  padding: 10px;
  flex: 1 1 auto;
  color: var(--bs-card-color);
  align-items: baseline;
  justify-content: center;
  max-width: 300px;
  margin: auto;
}

.card {
  width: 100%;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.saldo-value-container {
  margin-top: 10px;
}


body {
  background-color: #f6f6f6;
  font-family: 'Arial', sans-serif;
  margin: 0;
}


.container {
  margin-top: 20px;
  padding: 20px; 
}


h1 {
  color: #333;
  margin-bottom: 20px; 
}


.saldo-card {
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 20px; 
}

.alert {
  margin-top: 20px;
  text-align: center;
}


.btn {
  border-radius: 20px;
  margin: 0 5px; 
}


.progress {
  height: 20px;
  border-radius: 10px;
}


.card {
  border-radius: 8px;
  margin-top: 20px;
}


.card-body {
  padding: 15px; 
}


@media (max-width: 768px) {
  h1 {
    font-size: 24px;
  }

  .container {
    padding: 10px;
  }
  
  .btn {
    width: 100%; 
    margin-bottom: 10px; 
  }
}
