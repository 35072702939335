.header {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  background: var(--background-light);
  padding: 10px 20px;
  position: relative; 
  transition: all 0.3s ease;
  width: 100%;
  margin-right: 0; 
}

.header-container {
  height: 80px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  justify-content: space-between;
  padding: 1rem;
}

.logo {
  height: 200px;
}

.nav-container {
  margin-bottom: -55px;
}

.nav-list {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav-item {
  display: inline;
}

.nav-link {
  text-decoration: none;
  color: var(--text-color);
  padding-bottom: 5px;
  transition: border-bottom 0.3s ease;
}

.nav-link.active {
  border-bottom: 3px solid #239467;
}

.button {
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #239467;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border: none;
}

.button:hover {
  background-color: #303030;
  color: #ffffff;
}

.auth-buttons {
  display: flex;
  gap: 20px;
}

.opcoes {
  display: flex;
  gap: 20px;
  margin-right: 20px;
}

.centered-logo {
  justify-content: center; 
  height: 80px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  padding: 1rem;
}
