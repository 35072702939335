.custom-button {
    background-color: #048552;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition: background-color 0.3s ease;
    cursor: pointer;
    font-size: 16px;
    margin: 10px 0;
}

.custom-button:hover {
    background-color: #03663d;
    color: #fff;
}

.btn-editar {
    background-color: #048552;
}

.btn-editar:hover {
    background-color: #525252;
    color: #fff;
}

.btn-salvar {
    background-color: #048552;
    margin: 10px;
}

.btn-salvar:hover {
    background-color: #03663d;
}

.btn-cancelar {
    background-color: red;
}

.btn-cancelar:hover {
    background-color: darkred;
}
.btn-voltar {
    background-color: #525252;
    color: white; 
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    text-align: center;
    display: inline-block;
    transition: background-color 0.3s ease;
    cursor: pointer;
    font-size: 16px;
    margin: 10px 0;
    margin-bottom: 20px;
    text-decoration: none; 
}

.btn-voltar:link, 
.btn-voltar:visited
 {
    color: white; 
    text-decoration: none; 
}

.btn-voltar:hover {
    background-color: #6b6b6b;
    text-decoration: none; 
}


.btn-criar-conta{
    background-color: #525252; 
    color: white;
    border: none;
    padding: 10px 20px; 
    border-radius: 5px; 
    font-size: 16px; 
    cursor: pointer;
    transition: background-color 0.3s;
}
.btn-criar-conta:hover {
    background-color: #434343; 
}