.feature {
  align-items: center;
  margin-bottom: 20px;
}

.feature-content h3 {
  margin: 0;
  font-size: 18px;
}

.feature-content p {
  margin: 5px 0 0;
}

.features {
  background: #fff;
}
