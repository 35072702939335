.btn-editar {
  background-color: #048552;
  color: white;
}

.btn-salvar {
  background-color: #048552;
  color: white;
  margin: 10px;
}

.btn-cancelar {
  background-color: red;
  color: white;
}

.btn-voltar {
  background-color: #525252ff;
  color: white;
}

.btn-editar:hover {
  background-color: #525252ff;
  color: #ffff;
}

.card-title {
  margin: 18px;
  justify-content: center;
  display: flex;
}

h1 {
  text-align: center;
}

.nova-previ {
  background-color: #0d6efdff;
  border-radius: 5px;
  padding: 7px;
  cursor: pointer;
  font-size: 16px;
  display: inline-block;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.nova-previ:link, 
.nova-previ:visited {
  color: white; 
  text-decoration: none; 
}
