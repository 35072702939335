.planos-precos {
  background-color: #f8f9fa;
  padding: 40px;
  border-radius: 8px;
}

.plano {
  position: relative; 
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: box-shadow 0.3s ease;
  height: 100%;
  padding: 20px; 
}

.plano:hover {
  transform: translateY(-5px);
  background-color: var(--secondary-color);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.card-title {
  font-weight: bold;
  font-size: 1.5rem;
  color: #323232;
}

.card-text {
  font-size: 1.5rem;
  color: rgb(100, 100, 100);
  margin-bottom: 0.5rem;
  text-align: center;
}

.plano-titulo {
  font-weight: bold;
  color: var(--primary-color);
  font-size: 40px;
}

.titulo-plano {
  color: var(--primary-color);
  
}

.table {
  margin-top: 2rem;
  border-radius: 8px;
  overflow: hidden;
}

.table th {
  background-color: var(--primary-color);
  color: white;
  text-align: center;
}

.table td {
  text-align: center;
  padding: 1rem;
}

.mais-vendido .plano {
  border: 5px solid #FFD700; 
  padding: 10px; 
}

.badge-vendido {
  background-color: #FFD700;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  position: absolute;
  top: 10px;
  right: 10px;
  font-weight: bold;
  font-size: 0.9rem;
}
